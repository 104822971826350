import '../../Static.css';
import { Close } from '../Icon/Icon';
import './Menu.css';
import { useTranslation } from 'react-i18next';
import Scroll from 'react-scroll';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function Menu(props){
    const params = useParams();
    const { t } = useTranslation();
    function CloseFunc(e){
        if(e.target.className.indexOf('MenuCont') === -1 && e.target.className.indexOf('MenuContInside') === -1 && e.target.className.indexOf('MenuTitle') === -1 && e.target.className.indexOf('MenuMenu') === -1  && e.target.className.indexOf('MenuButton') === -1 && e.target.className.indexOf('HomeList') === -1)
            props.setOpen(false);
    }
    useEffect(() => {
        window.addEventListener('click', CloseFunc);
        return () => window.removeEventListener('click', CloseFunc);
    });
    return(
        <div className={props.Open ? 'MenuCont Menu--Open' : 'MenuCont'}>
            <div className='MenuContInside'>
                <div className='MenuTitle'>
                    <div className='MenuMenu'>{t("Menu.menu")}</div>
                    <div onClick={()=>props.setOpen(false)}><Close/></div>
                </div>
                <div className='MenuButton'>
                           {!props.Home ? <Link onClick={(e)=>{
                                e.preventDefault();
                                props.setOpen(false);
                                setTimeout(()=>window.location.href = 'http://nrg.by/'+params.lang, 1000);                                
                            }} to={'http://nrg.by/'+params.lang}>NRG</Link> : ''}
                        <div className='HomeList'>
                            <Scroll.Link className='MenuEst' to='MenuHome' spy={true} smooth={true} duration={500}><div onClick={props.onClick} value='1'>{t("Menu.est")}</div></Scroll.Link>
                            <Link onClick={(e)=>{
                                e.preventDefault();
                                props.setOpen(false);
                                setTimeout(()=>window.location.href = 'http://view.nrg.by/'+params.lang, 1000);                                
                            }} to={'http://view.nrg.by/'+params.lang}>{t("All.TheView")}</Link>
                            <Link onClick={(e)=>{
                                e.preventDefault();
                                props.setOpen(false);
                                setTimeout(()=>window.location.href = 'http://catering.nrg.by/'+params.lang, 1000);                                
                            }} to={'http://catering.nrg.by/'+params.lang}>{t("Gallery.Catering")}</Link>
                            <Link onClick={(e)=>{
                                e.preventDefault();
                                props.setOpen(false);
                                setTimeout(()=>window.location.href = 'http://poedem-poedim.nrg.by/'+params.lang, 1000);                                
                            }} to={'http://poedem-poedim.nrg.by/'+params.lang}>{t("Gallery.Poedempoedim")}</Link>
                            <Link onClick={(e)=>{
                                e.preventDefault();
                                props.setOpen(false);
                                setTimeout(()=>window.location.href = 'http://tuse.nrg.by/'+params.lang, 1000);                                
                            }} to={'http://tuse.nrg.by/'+params.lang}>{t("Gallery.Tuse")}</Link>
                        </div>
                        {props.Home ? <Scroll.Link to='MenuHome' onClick={()=>props.setOpen(false)} spy={true} smooth={true} duration={500}><div value='2'>{t("Menu.event")}</div></Scroll.Link> : ''}
                        {props.Home ? <Scroll.Link to='MenuHome' onClick={()=>props.setOpen(false)} spy={true} smooth={true} duration={500}><div value='3'>{t("Menu.gallery")}</div></Scroll.Link> : ''}
                        {!props.Home ? <Scroll.Link to='Gallery' onClick={()=>props.setOpen(false)} spy={true} smooth={true} duration={500}><div>{t("Menu.gallery")}</div></Scroll.Link> : ''}
                        {!props.Home ? <Scroll.Link to='Comment' onClick={()=>props.setOpen(false)} spy={true} smooth={true} duration={500}><div>{t("Menu.comment")}</div></Scroll.Link> : ''}
                        <Scroll.Link to='Contacts' onClick={()=>props.setOpen(false)} spy={true} smooth={true} duration={500}><div>{t("Menu.contact")}</div></Scroll.Link>
                    </div>
            </div>
        </div>
    )
}