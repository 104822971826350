import './RestHeader.css';
import '../../StaticElement/Static.css';
import Scroll from 'react-scroll'
import CustomSelect from '../../StaticElement/Component/CustomSelect/CustomSelect';
import { Instagram, Arrow, MenuButton, Telegram, Relax } from '../../StaticElement/Component/Icon/Icon';
import { useState, useRef, useEffect } from 'react';
import RestPhotoSliderButtons from './RestPhotoSliderButtons';
import Menu from '../../StaticElement/Component/Menu/Menu';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

export default function RestHeader(props){
    let { t, i18n } = useTranslation();
    const [Stage, setStage] = useState(2);
    const [MenuOpen, setMenuOpen] = useState(false);
    const [Resize, setResize] = useState(window.innerWidth);
    let Z1 = useRef(),
        Z2 = useRef(),
        Z3 = useRef();
    const [ZActive, setZActive] = useState(Z1);
    function Slider(st){
        switch(st){
            case 1:
                ZActive.current.classList.toggle('Z--Active');
                setZActive(Z1);
                break;
            case 2:
                ZActive.current.classList.toggle('Z--Active');
                Z2.current.classList.toggle('Z--Active');
                setZActive(Z2);
                break;
            case 3:
                ZActive.current.classList.toggle('Z--Active');
                Z3.current.classList.toggle('Z--Active')
                setZActive(Z3);
                break;
            default:
                break;
        }
    }
    useEffect(()=>{
        window.addEventListener('resize',()=> setResize(window.innerWidth));
        return () => window.removeEventListener('resize',()=> setResize(window.innerWidth));
    }, [Resize, props])
    useEffect(() => {
        let interval = setInterval(()=>{
            if(Stage + 1 > 3) setStage(1);
            else setStage(S=>S + 1);
            Slider(Stage);
        }, 5000)
        return () => clearInterval(interval);
    });

    return(
        <div className='RestHeaderCont'>
        <div className='Z1' ref={Z1} style={{background:`url(${props.HeaderImgArr[0]}) 50% 50%/cover no-repeat`}}/>
        <div className='Z2' ref={Z2} style={{background:`url(${props.HeaderImgArr[1]}) 50% 50%/cover no-repeat`}}/>
        <div className='Z3' ref={Z3} style={{background:`url(${props.HeaderImgArr[2]}) 50% 50%/cover no-repeat`}}/>
            {Resize > 600 
                ? 
                <div className='RestNavCont'>
                <Menu Open={MenuOpen} setOpen={setMenuOpen} onClick={props.onClick} Home={false}/>
                    <div className='RestNavButtonCont'>
                        <div className='RestLogoCont'><img src={require('../../StaticElement/Image/Catering/logo-dacha_0.png')} alt='logo' className='MenuLogoImg'/></div>
                        <Scroll.Link to='About' spy={true} smooth={true} duration={500}>{t("Menu.about")}</Scroll.Link>
                        {props.Menu ? <Scroll.Link to='Menu' spy={true} smooth={true} duration={500}>{t("Menu.menu")}</Scroll.Link> : ''}
                        <Scroll.Link to='Gallery' spy={true} smooth={true} duration={500}>{t("Menu.gallery")}</Scroll.Link>
                        <Scroll.Link to='Comment' spy={true} smooth={true} duration={500}>{t("Menu.comment")}</Scroll.Link>
                        {props.Tour ? <Scroll.Link to='3D' spy={true} smooth={true} duration={500}>{t("Menu.3D")}</Scroll.Link> : ''}
                        <Scroll.Link to='Contacts' spy={true} smooth={true} duration={500}>{t("Menu.contact")}</Scroll.Link>
                    </div>
                    <div className='RestNavButtonContTwo'>
                        <CustomSelect Link={props.Link}/>
                        {props.Menu ? <Link to={`/Menu${i18n.language.toUpperCase() === 'RU' ? 'RU' : 'EN'}`} className='RestMenuButton' id={props.Link + 'Button'}>{t("Menu.menu")}</Link> : ''}
                        <div className='RestHeaderInst'><Instagram onClick={()=>window.open(props.Inst)}/></div>
                        {props.Telegram !== undefined ? <div><Telegram className='RestTelegram' onClick={()=>window.open(props.Telegram)}/></div> : ''}
                        <Relax className='RestRelax' onClick={()=>window.open(props.Relax, '_blank')}/>
                        <div onClick={() => setMenuOpen(true)} className='RestMenuButtonCont1'><MenuButton/></div>
                    </div>
                </div>
                :
                <div className='RestNavCont'>
                <Menu Open={MenuOpen} setOpen={setMenuOpen} onClick={props.onClick} Home={false}/>
                    <div className='RestNavButtonCont'>
                        <img src={require('../../StaticElement/Image/Catering/logo-dacha_0.png')} alt='logo' className='MenuLogoImg'/>
                        {props.Menu ? <Link to={`/Menu${i18n.language.toUpperCase() === 'RU' ? 'RU' : 'EN'}`} className='RestMenuButton' id={props.Link + 'Button'}>{t("Menu.menu")}</Link> : ''}
                        <CustomSelect Link={props.Link}/>
                        <div className='RestHeaderInst'><Instagram onClick={()=>window.open(props.Inst)}/></div>
                        {props.Telegram !== undefined ? <Telegram className='RestTelegram' onClick={()=>window.open(props.Telegram)}/> : ''}
                        <Relax className='RestRelax' onClick={()=>window.open(props.Relax)}/>
                    </div>
                    <div className='RestNavButtonContTwo'>
                        <div onClick={() => setMenuOpen(true)}><MenuButton/></div>
                    </div> 
                </div>
            }
            <div className='RestHeaderSliderCont'>
                <div className='RestHeaderBack'/>
                <div className='RestHeaderTitleCont'>
                    <div className='RestHeaderTitleOne'>«{t(`RestTemplate.${props.Link}.Name`)}»<br/>{t(`RestTemplate.${props.Link}.Title`)}</div>
                    {props.Tour ? <div className='RestMenuButton Rest3DButton' id={props.Link + 'Button'} onClick={()=>window.open(props.TourSource)}>{t("All.Watch3D")}</div> : ''}
                    <a href={'tel:' + props.ContactTel} className='MenuEatHeaderBodyBron'>
                        <div style={{wordWrap:'none'}}>{t(`RestTemplate.${props.Link}.Bron`)}</div>
                        <div className='MenuEatHeaderBodyBronTel'>{props.ContactTel}</div>
                        <Arrow/>
                    </a>
                </div>
            </div>
            <div className='RestHeaderFooterCont'>
                <RestPhotoSliderButtons Slider={Slider} setStage={setStage} Stage={Stage}/>
            </div>
        </div>
    )
}