import { Navigate, Route, Routes } from 'react-router-dom';
import GalleryFull from '../GalleryFull/GalleryFull';
import MenuEat from '../MenuEat/MenuEat';
import RestTemplate from '../RestTemplate/RestTemplate';
import './App.css';

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/RU'/>}/>
      <Route path='/:lang' element={
      <RestTemplate
          HeaderImgArr={[require('../StaticElement/Image/Catering/1.webp'), require('../StaticElement/Image/Catering/2.webp'),require('../StaticElement/Image/Catering/3.webp')]}
          MenuPhoto={require('../StaticElement/Image/TheView/MenuPhoto.webp')}
          GalleryKitchen={[require('../StaticElement/Image/Catering/Kitchen_1.webp'), require('../StaticElement/Image/Catering/Kitchen_3.webp'), require('../StaticElement/Image/Catering/CATERINGLogo.png'), require('../StaticElement/Image/Catering/Kitchen_4.webp'), require('../StaticElement/Image/Catering/Kitchen_2.webp'), require('../StaticElement/Image/Catering/Kitchen_5.webp')]}
          GalleryInterior={[require('../StaticElement/Image/Catering/Interior_1.webp'), require('../StaticElement/Image/Catering/Interior_3.webp'), require('../StaticElement/Image/Catering/Interior_5.webp'), require('../StaticElement/Image/Catering/Interior_2.webp'), require('../StaticElement/Image/Catering/Interior_4.webp'), require('../StaticElement/Image/Catering/CATERING.png'), require('../StaticElement/Image/Catering/Interior_6.webp')]}
          ContactMap='https://yandex.ru/map-widget/v1/?um=constructor%3Ab0938738d8dcda0963f238ab6ef222dae0cc6cd61c900b1a79ce29726ef72427&amp;source=constructor'
          ContactMail='sales@magic-catering.by'
          ContactTel='+375 29 388 8488'
          Inst='https://www.instagram.com/magic.catering/'
          Link='Catering'
          Tour={false}
          Menu={false}
          Bar={false}
        />
      }/>
      <Route path='/Menu:lang' element={<MenuEat/>}/>
      <Route path='/Gallery:lang' element={<GalleryFull/>}/>
    </Routes>
  );
}